import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import {
  AnchorHTMLAttributes,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  useCallback
} from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { Location, useGetLocationDescriptor } from '~/hooks/use-history';

const isModifiedEvent = (event: MouseEvent<HTMLAnchorElement>): boolean =>
  event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

let hashFragment = '';
let observer: MutationObserver;
let asyncTimerId: number | undefined;
let scrollFunction: (element: Element) => void;

const getElAndScroll = (): boolean => {
  const element = document.getElementById(hashFragment);

  if (element !== null) {
    scrollFunction(element);

    reset();

    return true;
  }

  return false;
};

const reset = (): void => {
  hashFragment = '';

  if (observer !== undefined) {
    observer.disconnect();
  }

  if (asyncTimerId !== undefined) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = undefined;
  }
};

const doScroll = (): void => {
  // Push onto callback queue so it runs after the DOM is updated
  window.setTimeout(() => {
    if (!getElAndScroll()) {
      if (observer === null) {
        observer = new MutationObserver(getElAndScroll);
      }

      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true
      });

      // if the element doesn't show up in 10 seconds, stop checking
      asyncTimerId = window.setTimeout(() => {
        reset();
      }, 10000);
    }
  }, 0);
};

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  replace?: boolean;
  to: Location;
}

// TODO: NPU-218 & NPU-219 - Styles
export const Anchor = forwardRef<HTMLAnchorElement, PropsWithChildren<AnchorProps>>(function Anchor(
  {
    onClick,
    replace: replaceProp = false,
    target,
    to,
    ...restProps
  }: PropsWithChildren<AnchorProps>,
  ref
): JSX.Element {
  const { createHref, push, replace } = useHistory();
  const getLocationDescriptor = useGetLocationDescriptor();
  const location = getLocationDescriptor(to);

  if (!location.hash && !location.pathname) {
    throw new Error(
      `'to' property expected to be an object containing 'pathname' or 'hash' props. Received: ${JSON.stringify(
        to
      )}`
    );
  }

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>): void => {
      reset();

      onClick?.(event);

      if (isString(location.hash)) {
        hashFragment = location.hash.replace('#', '');
      }

      if (!isEmpty(hashFragment) && !location.pathname) {
        event.preventDefault();
        scrollFunction = (el) =>
          el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

        doScroll();
      }

      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        !target && // let browser handle "target=_blank" etc.
        location.pathname?.startsWith('/') && // internal URLs
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();

        if (replaceProp) {
          replace(location);
        } else {
          push(location);
        }
      } else if (location.pathname?.startsWith('http')) {
        // TODO: track
      }
    },
    [location, onClick, push, replace, replaceProp, target]
  );
  const href = createHref(location);
  const props = {
    ...restProps,
    href,
    key: href,
    onClick: handleClick,
    ref,
    rel: target === '_blank' ? 'noopener noreferrer' : undefined,
    target
  };

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} />;
});

export interface NavAnchorProps extends AnchorProps {
  activeClassName?: string;
  exact?: boolean;
  inactiveClassName?: string;
  match?: { exact?: boolean; path?: string };
  strict?: boolean;
}

export const NavAnchor = forwardRef<HTMLAnchorElement, NavAnchorProps>(function NavAnchor(
  {
    activeClassName = '',
    className,
    inactiveClassName = '',
    match,
    to,
    ...restProps
  }: NavAnchorProps,
  ref
): JSX.Element {
  const getLocationDescriptor = useGetLocationDescriptor();
  const location = getLocationDescriptor(to);
  const { pathname } = useLocation();
  const matchedPath = matchPath(pathname, {
    exact: match?.exact ?? false,
    path: match?.path ?? location.pathname
  });

  return (
    <Anchor
      {...restProps}
      className={`${className} ${matchedPath ? activeClassName : inactiveClassName}`}
      ref={ref}
      to={to}
    />
  );
});

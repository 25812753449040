import { useContext } from 'react';

import { Context, FootnotesContext } from '~/contexts/Footnotes/context';

/**
 * Returns a function to register a new footnote as well as the list of registered footnotes.
 *
 * @return {FootnotesContext}
 * @throws Error
 */
export function useFootnotesContext(): FootnotesContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useFootnotesContext must be used within a FootnotesContextProvider');
  }

  return context;
}

import { toast as rhToast } from 'react-hot-toast';

import { CONTENT } from '~/components/Toaster/content';
import {
  TOAST_DURATION_MAX_MS,
  TOAST_DURATION_MIN_MS,
  ToastReturn,
  ToastVariant,
  ToastVariantConfig
} from '~/components/Toaster/types';

const variantParameters: Record<ToastVariant, ToastVariantConfig> = {
  [ToastVariant.Success]: {
    duration: TOAST_DURATION_MIN_MS,
    style: {
      borderLeftColor: '#79CDA8'
    }
  },
  [ToastVariant.Error]: {
    defaultMessage: CONTENT.errorMessage,
    duration: TOAST_DURATION_MAX_MS,
    style: {
      borderLeftColor: '#FF8D74'
    }
  },
  [ToastVariant.Info]: {
    duration: 6000,
    style: {
      borderLeftColor: '#818181'
    }
  },
  [ToastVariant.Warning]: {
    duration: TOAST_DURATION_MAX_MS,
    style: {
      borderLeftColor: '#FFC895'
    }
  }
};

export const toast: ToastReturn = ({ message, variant, ...restArgs }) => {
  const { defaultMessage, duration, style } = variantParameters[variant];

  if (duration < TOAST_DURATION_MIN_MS) {
    throw new Error(`Duration is below min (${TOAST_DURATION_MIN_MS})`);
  } else if (duration > TOAST_DURATION_MAX_MS) {
    throw new Error(`Duration is above max (${TOAST_DURATION_MAX_MS})`);
  }

  const toastMessage = message ?? defaultMessage;
  if (!toastMessage) {
    throw new Error('empty toast message (not passed and no default)!');
  }

  return rhToast(toastMessage, {
    ...restArgs,
    duration,
    style: { borderLeft: '8px solid', ...style }
  });
};

toast.dismiss = rhToast.dismiss;
toast.remove = rhToast.remove;

import { AccessToken, IDToken, OktaAuthOptions, RefreshToken, Tokens } from '@okta/okta-auth-js';
import { createContext } from 'react';

export interface AuthenticationContext {
  configuration: OktaAuthOptions;
  signIn: (path: string, tokens: Tokens) => void;
  signOut: () => void;
  state: AuthenticationState;
}

export interface AuthenticationState {
  accessToken?: AccessToken;
  error?: Error;
  idToken?: IDToken;
  isAuthenticated: boolean;
  refreshToken?: RefreshToken;
}

export interface LocationState {
  from: Location;
}

export const Context = createContext<AuthenticationContext | undefined>(undefined);

Context.displayName = 'AuthenticationContext';

import clsx from 'clsx';
import { PropsWithChildren, Suspense } from 'react';

import { LoadingOverlay } from '~/components/loading-overlay';

export interface EmptyLayoutProps {
  className?: string;
}

export const EmptyLayout = ({ children, className }: PropsWithChildren<EmptyLayoutProps>) => {
  return (
    <div className={clsx('flex flex-auto flex-col', className)}>
      <Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
    </div>
  );
};

import { FunctionComponent, memo, NamedExoticComponent, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { Footer } from '~/components/footer';
import { HeaderAccount } from '~/components/HeaderAccount';
import { AccountIdParam, SubaccountIdParam } from '~/constants/paths';
import { AccountContextProvider } from '~/contexts/Account';
import { EmptyLayout } from '~/layouts/EmptyLayout';

type MemorizedAccountLayoutProps = {
  accountId: string;
  subaccountId: string;
};

const MemorizedAccountLayout: NamedExoticComponent<PropsWithChildren<MemorizedAccountLayoutProps>> =
  memo(
    function MemorizedAccountLayout({
      accountId,
      children,
      subaccountId
    }: PropsWithChildren<MemorizedAccountLayoutProps>) {
      return (
        <AccountContextProvider accountId={accountId} subaccountId={subaccountId}>
          <div className="flex flex-col min-h-screen">
            <HeaderAccount />
            <EmptyLayout>{children}</EmptyLayout>
            <Footer fixed />
          </div>
        </AccountContextProvider>
      );
    },
    (prevProps, nextProps): boolean => {
      return (
        prevProps.accountId === nextProps.accountId &&
        prevProps.subaccountId === nextProps.subaccountId
      );
    }
  );

export const AccountLayout: FunctionComponent = ({ children }) => {
  const { accountId, subaccountId } = useParams<AccountIdParam & SubaccountIdParam>();

  return (
    <MemorizedAccountLayout accountId={accountId} subaccountId={subaccountId}>
      {children}
    </MemorizedAccountLayout>
  );
};

import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { PATH_CONTACT_US, PATH_TERMS_AND_CONDITIONS } from '~/constants/paths';
import { Footnote, useFootnotesContext } from '~/contexts/Footnotes';

import { Anchor } from './anchor';

export interface FooterProps {
  fixed?: boolean;
}

export const Footer = ({ fixed = false }: FooterProps): JSX.Element => {
  const footnotesContext = useFootnotesContext();
  const footnotes = Object.entries(footnotesContext.footnotes);

  return (
    <footer className="bg-black p-6">
      <div className={clsx('flex flex-col gap-5', { 'container mx-auto': fixed })}>
        <div className="flex flex-col gap-5 md:flex-row sm:text-base text-white text-xs xl:px-4">
          <p className="flex-auto">&copy; NYDIG {new Date().getFullYear()}</p>
          <ul className="flex flex-col sm:flex-row gap-5">
            <li>
              <Anchor className="hover:text-gray-300 text-white" to={PATH_CONTACT_US}>
                Contact Us
              </Anchor>
            </li>
            <li>
              <Anchor
                className="hover:text-gray-300 text-white"
                target="_blank"
                to="https://nydig.com/privacy-policy/"
              >
                Privacy Policy
              </Anchor>
            </li>
            <li>
              <Anchor className="hover:text-gray-300 text-white" to={PATH_TERMS_AND_CONDITIONS}>
                Terms and Conditions
              </Anchor>
            </li>
          </ul>
        </div>
        {!isEmpty(footnotes) && (
          <div className="flex flex-col gap-5 text-gray-500 text-xs xl:px-4">
            {footnotes.map(([key, { symbol, value }]: [key: string, value: Footnote]) => (
              <div id={`footnote:${symbol}`} key={key}>
                <sup className="mr-1">{symbol}</sup>
                <span>{value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </footer>
  );
};

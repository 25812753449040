import { ReactNode } from 'react';

import { Button, ButtonStyle } from '~/components/button';
import { ModalDialog } from '~/components/modal-dialog';

export interface ErrorOverlayProps {
  error?: Error | null;
  message?: ReactNode;
  title: string;
}

export const ErrorOverlay = ({ error, message, title }: ErrorOverlayProps) => {
  const reload = () => window.location.reload();

  return (
    <ModalDialog header={title} isOpen onClose={reload}>
      <div className="mb-5">{message ?? error?.message}</div>
      <Button buttonStyle={ButtonStyle.Primary} className="float-right" onClick={reload}>
        Reload
      </Button>
    </ModalDialog>
  );
};

import { Spinner } from './spinner';

export const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 h-full w-full content-center">
      <div className="absolute inset-0 flex items-center justify-center">
        <Spinner />
      </div>
    </div>
  );
};

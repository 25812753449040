import { SubAccountType } from '~/data/types/subaccount';
import { User, UserRelationship } from '~/data/types/user';

export enum AccountState {
  /**
   * Active (fully opened) state.
   */
  Active = 'ACTIVE',

  /**
   * Approved state.
   */
  Approved = 'APPROVED',

  /**
   * Closed state. Not implemented in back-end.
   */
  Closed = 'CLOSED',

  /**
   * Initial state.
   */
  Created = 'CREATED',

  /**
   * Denied state.
   */
  Denied = 'DENIED',

  /**
   * Locked state.
   */
  Locked = 'LOCKED',

  /**
   * Pending Auth Setup state.
   */
  PendingAuthSetup = 'PENDING_AUTH_SETUP'
}

export enum SubAccountState {
  /**
   * Active terminal state.
   */
  Active = 'ACTIVE',

  /**
   * Marks a sub-account as approved. Next state is PendingSubAccountSetup.
   */
  Approved = 'APPROVED',

  /**
   * Closed terminal state.
   */
  Closed = 'CLOSED',

  /**
   * Marks a sub-account as denied. Terminal state.
   */
  Denied = 'DENIED',

  /**
   * Locked terminal state.
   */
  Locked = 'LOCKED',

  /**
   * Initial state. Marks a sub-account as in pending application completion. Next state is
   * either PendingReview or Approved.
   */
  PendingApplication = 'PENDING_APPLICATION',

  /**
   * Marks a sub-account as in transition state while the Custody sub-account access is being configured.
   * Next state is Active.
   */
  PendingAuthSetup = 'PENDING_AUTH_SETUP',

  /**
   * Marks a sub-account as in Manual Review. Next state is either Approved or Denied.
   */
  PendingReview = 'PENDING_REVIEW',

  /**
   * Marks a sub-account as in transition state while a Custody sub-account is being
   * provisioned. Next state is PendingAuthSetup.
   */
  PendingSubAccountSetup = 'PENDING_SUBACCOUNT_SETUP'
}

export enum UserAccountState {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Inactive = 'INACTIVE',
  PinCreated = 'PIN_CREATED'
}

export interface AccountUser extends Omit<User, 'name'> {
  relationship: UserRelationship;
  userAccountState: UserAccountState;
}

export interface Account {
  createdAt?: string;
  id: string;
  state: AccountState;
  subaccounts: SubAccount[];
  users: Partial<AccountUser>[];
}

export interface SubAccount {
  externalId?: string;
  id: string;
  legalName?: string;
  subaccountState: SubAccountState;
  type: SubAccountType;
}

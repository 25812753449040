import { log, LogLevel } from '@nydig/sweater-vest';
import { canUseDom } from '@nydig/sweater-vest-globals';
import noop from 'lodash/noop';
import { useDebugValue, useEffect, useState } from 'react';

import { BREAKPOINTS } from '~/constants/breakpoints';

const cssRegex = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/;
const getMediaQuery = (minBreakpoint: string, maxBreakpoint?: string) => {
  const selector = minBreakpoint ? `(min-width: ${minBreakpoint})` : '';

  if (maxBreakpoint) {
    const match = maxBreakpoint.match(cssRegex);

    if (match) {
      const [, maxBpSize, maxBpUnit] = match;

      return `${selector ? `${selector} and ` : ''}(max-width: ${
        Number.parseFloat(maxBpSize) - 1
      }${maxBpUnit})`;
    }

    log(LogLevel.Warn, `Invalid maxBreakpoint: ${maxBreakpoint}`);
  }

  return selector;
};

const supportMatchMedia = canUseDom && typeof window.matchMedia !== 'undefined';
function stripMediaTag(queryInput: string): string {
  return queryInput.replace(/^@media( ?)/m, '');
}

export const EXTRA_SMALL_MEDIA_QUERY = getMediaQuery(BREAKPOINTS.XS);
export const SMALL_MEDIA_QUERY = getMediaQuery(BREAKPOINTS.SM);
export const MEDIUM_MEDIA_QUERY = getMediaQuery(BREAKPOINTS.MD);
export const LARGE_MEDIA_QUERY = getMediaQuery(BREAKPOINTS.LG);
export const EXTRA_LARGE_MEDIA_QUERY = getMediaQuery(BREAKPOINTS.XL);
export const XX_LARGE_MEDIA_QUERY = getMediaQuery(BREAKPOINTS['2XL']);

export type MediaQueryOptions<T> = {
  defaultMatches?: T;
  matchMedia?: (query: string) => MediaQueryList;
  noSsr?: boolean;
  ssrMatchMedia?: (query: string) => MediaQueryList;
};

export function useMediaQueryMatches(options: MediaQueryOptions<string> = {}) {
  const { defaultMatches = BREAKPOINTS.XS } = options;
  const matchedBreakpoints = Object.values(BREAKPOINTS).map((breakpoint) => ({
    breakpoint,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    match: useMediaQuery(getMediaQuery(breakpoint), { ...options, defaultMatches: true })
  }));

  return matchedBreakpoints.reduce(
    (breakpoint, item) => (item.match ? item.breakpoint : breakpoint),
    defaultMatches
  );
}

export function useMediaQuery(queryInput: string, options: MediaQueryOptions<boolean> = {}) {
  const query = stripMediaTag(queryInput);
  const {
    defaultMatches = false,
    matchMedia = supportMatchMedia ? window.matchMedia : null,
    noSsr = false,
    ssrMatchMedia = null
  } = options;

  const [match, setMatch] = useState(() => {
    if (noSsr && supportMatchMedia) {
      return matchMedia?.(query).matches;
    }

    if (ssrMatchMedia) {
      return ssrMatchMedia(query).matches;
    }

    // Once the component has mounted, we rely on the
    // event listeners to return the correct matches value.
    return defaultMatches;
  });

  useEffect(() => {
    let active = true;

    if (!supportMatchMedia) {
      return noop;
    }

    const queryList = matchMedia?.(query);
    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList?.matches);
      }
    };
    updateMatch();
    queryList?.addListener(updateMatch);
    return () => {
      active = false;
      queryList?.removeListener(updateMatch);
    };
  }, [query, matchMedia]);

  if (!__NODE_ENV_PRODUCTION__) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDebugValue({ match, query });
  }

  return match;
}

import { PropsWithChildren } from 'react';

import { Footer } from '~/components/footer';
import { HeaderDefault } from '~/components/HeaderDefault';
import { EmptyLayout } from '~/layouts/EmptyLayout';

export interface DefaultLayoutProps {
  fixed?: boolean;
}

export const DefaultLayout = ({
  children,
  fixed = false
}: PropsWithChildren<DefaultLayoutProps>) => {
  return (
    <div className="flex flex-col min-h-screen">
      <HeaderDefault />
      <EmptyLayout>{children}</EmptyLayout>
      <Footer fixed={fixed} />
    </div>
  );
};

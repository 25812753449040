import { log, LogLevel } from '@nydig/sweater-vest';
import * as H from 'history';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import { useCallback, useMemo } from 'react';
import { generatePath, useHistory as useReactRouterHistory, useParams } from 'react-router-dom';

export interface PathDescriptorObject {
  params?: { [paramName: string]: boolean | number | string | undefined };
  pathname: H.Pathname;
}

export type SearchDescriptorObject =
  | Record<string, boolean | number | string | null | undefined>
  | string[][]
  | URLSearchParams
  | string;

export interface LocationDescriptorObject<S = H.LocationState> {
  hash?: H.Hash;
  path?: H.Pathname | PathDescriptorObject;
  search?: H.Search | SearchDescriptorObject;
  state?: S;
}

export type Location<S = H.LocationState> = H.Location<S> | H.Path | LocationDescriptorObject<S>;

/**
 * Returns a function which transforms a Location to LocationDescriptor.
 *
 * @return function
 */
export const useGetLocationDescriptor = (): ((
  location: Location
) => H.LocationDescriptorObject) => {
  const currentRouteParams = useParams();
  return useCallback(
    (location: Location): H.LocationDescriptorObject => {
      if (isObject(location)) {
        const { pathname } = location as H.Location;
        const { hash, path, search, state } = location as LocationDescriptorObject;

        return {
          hash,
          pathname: isNil(path)
            ? pathname
            : isString(path)
              ? path
              : generatePath(path.pathname, { ...currentRouteParams, ...path.params }),
          search: isNil(search)
            ? undefined
            : isString(search)
              ? (search as H.Search)
              : new URLSearchParams(
                  Object.fromEntries(
                    Object.entries(search)
                      .filter(
                        ([, value]: [string, string | null | undefined]) => !isUndefined(value)
                      )
                      .map(([key, value]: [string, string | null]) => [
                        key,
                        isNull(value) ? '' : value
                      ])
                  )
                ).toString(),
          state
        };
      } else if (isString(location)) {
        return {
          pathname: location as H.Path
        };
      }
      throw Error(
        'Invalid URI. `to` must either be an object with a combination of `hash`, `pathname` or `search` specified or as a string pathname.'
      );
    },
    [currentRouteParams]
  );
};

export type History = Omit<H.History, 'push' | 'replace'> & {
  push: (location: Location) => void;
  replace: (location: Location) => void;
};

export const useHistory = (): History => {
  const getLocationDescriptor = useGetLocationDescriptor();
  const history = useReactRouterHistory();

  return useMemo(
    () => ({
      ...history,
      push: (location: Location) => {
        const locationDescriptor = getLocationDescriptor(location);

        log(LogLevel.Info, 'history.push', { locationDescriptor });

        history.push(locationDescriptor);
      },
      replace: (location: Location) => {
        const locationDescriptor = getLocationDescriptor(location);

        log(LogLevel.Info, 'history.replace', { locationDescriptor });

        history.replace(locationDescriptor);
      }
    }),
    [getLocationDescriptor, history]
  );
};

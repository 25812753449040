import { merge } from 'lodash';
import { createContext, PropsWithChildren, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingOverlay } from '~/components/loading-overlay';
import { AccountHolderIdParam } from '~/constants/paths';
import { ActiveAccount, ActiveSubAccount } from '~/contexts/Account/types';
import { useUserContext } from '~/contexts/User';
import { Account, AccountUser } from '~/data/types/account';
import { useAccountQuery } from '~/data/use-account';

const Context = createContext<ActiveAccount | undefined>(undefined);

Context.displayName = 'AccountContext';

export interface AccountContextProviderProps {
  accountId: string;
  subaccountId: string;
}

export const AccountContextProvider = ({
  accountId,
  children,
  subaccountId
}: PropsWithChildren<AccountContextProviderProps>) => {
  const {
    data: account,
    isError,
    isLoading
  } = useAccountQuery({
    id: accountId
  });
  const { accountHolderId } = useParams<AccountHolderIdParam>();
  const user = useUserContext();
  const { id: userId } = user;

  if (isError) {
    // TODO: Error modal?
    return null;
  }

  if (isLoading) {
    return <LoadingOverlay />;
  }

  const { subaccounts, users, ...restAccount } = account as Account;
  const subaccount = subaccounts?.find(({ id }) => id === subaccountId) as ActiveSubAccount;
  const userOther = users?.find(({ id }) => id !== userId) as AccountUser;
  const userSelf = merge({}, user, users?.find(({ id }) => id === userId) as AccountUser);
  const userCurrent =
    accountHolderId === userId
      ? userSelf
      : (users?.find(({ id }) => id === accountHolderId) as AccountUser);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    ...restAccount,
    subaccount,
    subaccounts,
    userCurrent,
    userOther,
    userSelf,
    users
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Returns data about the current account.
 *
 * @returns AccountContext
 * @throws Error
 */
export function useAccountContext(): ActiveAccount {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useAccountContext must be used within a AccountContextProvider');
  }

  return context;
}

export * from './types';

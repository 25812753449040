import { ReactNode, useEffect } from 'react';
import CloseIcon from 'zondicons/close.svg';

import { H1 } from './typography/h1';

export interface ModalDialogProps {
  children: ReactNode;
  header?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalDialog = ({ children, header, isOpen, onClose }: ModalDialogProps) => {
  useEffect(() => {
    const escFunction = (e: KeyboardEvent) => {
      // IE 11, Edge (prior to using Bink?) use 'Esc'
      if (e.key === 'Esc' || e.key === 'Escape' || e.keyCode === 27) {
        onClose();
      }
    };

    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-20 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-2 px-2 sm:pt-4 sm:px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75">
            <button
              className="fixed inset-0 h-full w-full focus:outline-none cursor-default"
              onClick={onClose}
              tabIndex={-1}
              type="button"
            />
          </div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
        &#8203;
        <div
          aria-labelledby="modal-headline"
          aria-modal="true"
          className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full p-2 sm:p-8"
          role="dialog"
        >
          {header && (
            <>
              <button
                className="float-right focus:outline-none hover:text-gray-500 text-black"
                onClick={onClose}
                type="button"
              >
                <CloseIcon className="fill-current w-5 h-5" />
              </button>
              <H1 id="modal-headline">{header}</H1>
            </>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

import { Properties } from 'csstype';
import { CSSProperties, forwardRef, PropsWithChildren } from 'react';

export interface ToastProps {
  id: string;
  offset: number;
  style?: Properties | undefined;
  visible?: boolean;
}

const Toast = forwardRef<HTMLDivElement, PropsWithChildren<ToastProps>>(function Toast(
  { children, id, offset, style, visible },
  ref
) {
  return (
    <div
      className="absolute space text-base w-full space p-4 m-4 rounded-md text-xs"
      key={id}
      ref={ref}
      role="alert"
      style={{
        background: '#F1F1F1',
        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
        display: 'flex',

        opacity: visible ? 1 : 0,
        transform: `translateY(${offset}px)`,
        transition: 'all 0.5s ease-out',
        ...(style as CSSProperties)
      }}
    >
      {children}
    </div>
  );
});

export default Toast;

import {
  HttpHeader,
  HttpMethod,
  HttpStatus,
  log,
  LogLevel,
  MediaType,
  RequestMode
} from '@nydig/sweater-vest';
import { isArray, isDate, isNil, isObject } from 'lodash';

import { useAuthenticationContext } from '~/contexts/Authentication/hooks';

export function keysToCamel(object: {}): unknown {
  return convertKeys(object, toCamel);
}

export function toCamel(s: string): string {
  return s.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function convertKeys(object: any, converter: (s: string) => string): any {
  if (isDate(object)) {
    return object;
  }

  if (isArray(object)) {
    return object.map((i: any) => convertKeys(i, converter));
  } else if (isObject(object)) {
    const converted: any = {};

    Object.keys(object).forEach((key: string) => {
      converted[converter(key)] = convertKeys((object as any)[key], converter);
    });

    return converted;
  }

  return object;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export enum RootNodes {
  DATA = 'data',
  ERRORS = 'errors'
}

export type Options<TRequest> = Omit<RequestInit, 'body'> & { body?: TRequest };

export interface ServerResponseErrors {
  [field: string]: string[];
}

export interface ServerResponse<T = void> {
  body: T | null;
  errors: ServerResponseErrors | null;
  isValid: boolean;
  response?: Response;
}

const getBody = <TResponse>(body: TResponse | null): BodyInit | null => {
  if (!isNil(body)) {
    return body instanceof ArrayBuffer ||
      body instanceof Blob ||
      body instanceof FormData ||
      body instanceof ReadableStream ||
      body instanceof URLSearchParams
      ? body
      : JSON.stringify(body);
  }

  return null;
};

const hasBody = (response: Response): boolean => {
  const { headers, status } = response;
  const contentLength = headers.get(HttpHeader.ContentLength);
  const contentType = headers.get(HttpHeader.ContentType);
  return (
    ![HttpStatus.NoContent, HttpStatus.ResetContent].includes(status) &&
    contentLength !== '0' &&
    !!contentType?.includes('json')
  );
};

const parseBody = async <TResponse>(
  response: Response,
  rootNode: RootNodes
): Promise<TResponse | null> => {
  if (!hasBody(response)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response as any;
  }

  const json = await response.json();
  return keysToCamel(json[rootNode]) as TResponse;
};

const traceRequest = async (url: string, options: RequestInit): Promise<Response> => {
  const fn = () => fetch(url, options);
  if (__NODE_ENV_DEVELOPMENT__) {
    const startTime = Date.now();
    try {
      return await fn();
    } finally {
      log(LogLevel.Log, `${options.method}: ${url} (${Date.now() - startTime}ms)`);
    }
  } else {
    return fn();
  }
};

export function useGetRequest<TResponse>() {
  const request = useRequest<TResponse>();
  return async (url: string, options: RequestInit = {}): Promise<TResponse> => {
    const response = request(url, {
      ...options,
      method: HttpMethod.Get
    });

    return (await response).body as TResponse;
  };
}

export function usePatchRequest<TRequest, TResponse = TRequest>() {
  const request = useRequest<TResponse>();
  return async (url: string, options: Options<TRequest> = {}): Promise<ServerResponse<TResponse>> =>
    request(url, {
      ...options,
      body: getBody(options.body),
      method: HttpMethod.Patch
    });
}

export function usePostRequest<TRequest, TResponse = TRequest>() {
  const request = useRequest<TResponse>();
  return async (url: string, options: Options<TRequest> = {}): Promise<ServerResponse<TResponse>> =>
    request(url, {
      ...options,
      body: getBody(options.body),
      method: HttpMethod.Post
    });
}

export function useRequest<TResponse>() {
  const {
    signOut,
    state: { accessToken }
  } = useAuthenticationContext();

  return async (path: string, options: RequestInit = {}): Promise<ServerResponse<TResponse>> => {
    const init: RequestInit = {
      ...options,
      headers: new Headers({
        [HttpHeader.Authorization]: `Bearer ${accessToken?.accessToken}`,
        [HttpHeader.ContentType]: MediaType.ApplicationJson
      }),
      mode: RequestMode.SameOrigin
    };
    const url = `/api${path}`;
    const response = await traceRequest(url, init);
    const { status } = response;
    if (status === HttpStatus.Unauthorized) {
      signOut();
    }

    if (response.ok) {
      const body = await parseBody<TResponse>(response, RootNodes.DATA);
      return {
        body,
        errors: null,
        isValid: true,
        response
      };
    }

    if (response.status === HttpStatus.UnprocessableEntity) {
      const errors = await parseBody<ServerResponseErrors>(response, RootNodes.ERRORS);
      return {
        body: null,
        errors,
        isValid: false,
        response
      };
    }

    const message = `${response.status} Response from ${init.method}: ${url}`;
    return Promise.reject(new Error(message));
  };
}

import { Anchor } from './anchor';
import { ErrorOverlay } from './error-overlay';

export const ErrorFallback = () => {
  return (
    <ErrorOverlay
      message={
        <p>
          Please contact{' '}
          <Anchor className="hover:no-underline underline" to="mailto:clientservices@nydig.com">
            clientservices@nydig.com
          </Anchor>{' '}
          if you are still having issues.
        </p>
      }
      title="Oops, looks like something went wrong."
    />
  );
};

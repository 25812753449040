import { useQuery, UseQueryResult } from 'react-query';

import { Account } from '~/data/types/account';
import { useGetRequest } from '~/hooks/use-fetch';

const accountUrl = '/onboarding/account';

export function useAccountQuery(account: Partial<Account>): UseQueryResult<Account, Error> {
  const { id } = account;
  const get = useGetRequest<Account>();
  const url = `${accountUrl}/${id}`;

  return useQuery<Account, Error>([url], async (): Promise<Account> => get(url));
}

export function useAccountsQuery(): UseQueryResult<Account[], Error> {
  const get = useGetRequest<Account[]>();
  const url = `${accountUrl}`;

  return useQuery<Account[], Error>([url], async (): Promise<Account[]> => get(url));
}

import clsx from 'clsx';
import { isArray } from 'lodash';
import { ElementType, Fragment, ReactNode, useEffect, useMemo } from 'react';

import { useFootnotesContext } from '~/contexts/Footnotes/hooks';

export interface FootnoteReferenceProps {
  as?: ElementType;
  children?: ReactNode;
  className?: string;
  data?: { [index: string]: unknown };
  footnote: string[] | string;
}

export const FootnoteReference = ({
  as,
  children,
  className,
  data,
  footnote
}: FootnoteReferenceProps) => {
  let Component = as;

  if (!Component) {
    Component = children ? 'p' : Fragment;
  }

  const componentProps = children ? { className: clsx({ flex: !as && className }) } : {};
  const { footnotes: footnotesMap, register, unregister } = useFootnotesContext();
  const footnotes = useMemo(() => (isArray(footnote) ? footnote : [footnote]), [footnote]);

  useEffect(() => {
    for (const currentFootnote of footnotes) {
      register(currentFootnote, data);
    }

    return () => {
      for (const currentFootnote of footnotes) {
        unregister(currentFootnote);
      }
    };
  }, [data, footnotes, register, unregister]);

  return (
    <Component {...componentProps}>
      {children}
      <sup className={clsx('ml-1 text-xs', className)}>
        {footnotes
          .map(
            (currentFootnote) =>
              footnotesMap[currentFootnote.toUpperCase()]?.symbol ?? 'UNKNOWN_FOOTNOTE'
          )
          .join(',')}
      </sup>
    </Component>
  );
};

export * from './hooks';
export * from './provider';
export * from './types';

import { createContext } from 'react';

import { Footnotes, Register, Unregister } from '~/contexts/Footnotes/types';

export interface FootnotesContext {
  footnotes: Footnotes;
  register: Register;
  unregister: Unregister;
}

/**
 * The Footnotes context.
 *
 * @typedef FootnotesContext
 * @property {Footnotes}  footnotes  - Collection of registered footnotes.
 * @property {Register}   register   - Function to register a footnote reference
 * @property {Unregister} unregister - Function to unregister a footnote reference.
 */
export const Context = createContext<FootnotesContext | undefined>(undefined);

Context.displayName = 'FootnotesContext';

import {
  boolean,
  Box,
  log,
  LogLevel,
  object,
  Tag,
  TagVariant,
  useLocalStorage
} from '@nydig/sweater-vest';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { EVENT_KEYDOWN } from '~/constants/events';
import { KEY_M } from '~/constants/keys';

const mockProviderConfigSchema = object({
  mocks: object({
    enabled: boolean().required()
  }).required()
});

const defaultConfig = {
  mocks: {
    enabled: false
  }
};

export const MockProvider =
  __NODE_ENV_DEVELOPMENT__ || __IS_PR__
    ? ({ children }: PropsWithChildren<{}>) => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { setupWorker } = require('msw');
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { handlers } = require('~/mocks/handlers');
        const [initialized, setInitialized] = useState<boolean>(false);
        const [
          {
            mocks: { enabled }
          },
          setConfig
        ] = useLocalStorage('portal.mocks', mockProviderConfigSchema, defaultConfig);
        const handleKeyDown = useCallback(
          ({ altKey, code, ctrlKey }) => {
            if (altKey && code === KEY_M && ctrlKey) {
              log(LogLevel.Info, `Mocks ${enabled ? 'dis' : 'en'}abled`);

              setConfig({
                mocks: { enabled: !enabled }
              });

              window.location.reload();
            }
          },
          [enabled, setConfig]
        );

        useEffect(() => {
          document.addEventListener(EVENT_KEYDOWN, handleKeyDown);

          return () => document.removeEventListener(EVENT_KEYDOWN, handleKeyDown);
        }, [handleKeyDown]);

        useEffect(() => {
          if (enabled) {
            setupWorker(...handlers)
              .start({
                onUnhandledRequest: 'bypass'
              })
              .then(() => {
                setInitialized(true);
              });
          } else {
            setInitialized(true);
          }
        }, [setupWorker, handlers, enabled]);

        return initialized ? (
          <>
            {enabled && <MockIndicator />}
            {children}
          </>
        ) : null;
      }
    : ({ children }: PropsWithChildren<{}>) => {
        return <>{children}</>;
      };

const MockIndicator = () => (
  <Box
    style={{
      bottom: 20,
      position: 'fixed',
      right: 20
    }}
  >
    <Tag variant={TagVariant.Highlighted}>MOCKS ENABLED</Tag>
  </Box>
);

import { log, LogLevel } from '@nydig/sweater-vest';
import { PropsWithChildren } from 'react';
import {
  QueryClient,
  QueryClientProvider as ReactQueryQueryClientProvider,
  setLogger
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { MockProvider } from '~/mocks';

setLogger({
  error: (error) => {
    log(LogLevel.Error, error);
  },
  log: (message) => {
    log(LogLevel.Log, message);
  },
  warn: (message) => {
    log(LogLevel.Warn, message);
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {},
    queries: {
      notifyOnChangeProps: 'tracked',
      refetchOnWindowFocus: false,
      retry: __NODE_ENV_DEVELOPMENT__ ? false : undefined,
      suspense: false
    }
  }
});

export const QueryClientProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <MockProvider>
      <ReactQueryQueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {children}
      </ReactQueryQueryClientProvider>
    </MockProvider>
  );
};

import { clearDatadogRumUser, setDatadogRumUser } from '@nydig/sweater-vest-datadog';
import { __APP_ENVIRONMENT_PROD__ } from '@nydig/sweater-vest-globals';
import {
  AccessToken,
  AuthState,
  IDToken,
  OktaAuth,
  RefreshToken,
  Tokens,
  toRelativeUrl
} from '@okta/okta-auth-js';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { __APP_OKTA_CLIENT_ID__, __APP_OKTA_ISSUER__ } from '~/constants/env';
import { PATH_ACCOUNTS, PATH_AUTHENTICATION__SIGN_IN } from '~/constants/paths';
import { AuthenticationState, Context } from '~/contexts/Authentication/context';
import { useHistory } from '~/hooks/use-history';

const redirectUri = window.location.origin;
const oktaAuthConfiguration = {
  clientId: __APP_OKTA_CLIENT_ID__,
  devMode: __NODE_ENV_DEVELOPMENT__,
  issuer: __APP_OKTA_ISSUER__,
  maxClockSkew: 300, // 5 minutes
  postLogoutRedirectUri: `${redirectUri}${PATH_AUTHENTICATION__SIGN_IN}`,
  redirectUri: `${redirectUri}${PATH_ACCOUNTS}`,
  scopes: ['email', 'openid', 'profile'].concat(__APP_ENVIRONMENT_PROD__ ? [] : ['portal/access']),
  tokenManager: {
    // Emit expired event 2 minutes before expiration
    // Tokens accessed with tokenManager.get() will auto-renew within 2 minutes of expiration
    expireEarlySeconds: 120
  }
};
const service = new OktaAuth(oktaAuthConfiguration);

export const AuthenticationContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const { replace } = useHistory();
  const queryClient = useQueryClient();
  const [state, setState] = useState<AuthenticationState | undefined>(undefined);

  useEffect(() => {
    service.authStateManager.subscribe((authState: AuthState) => {
      if (authState) {
        const { accessToken, error, idToken, isAuthenticated, refreshToken } = authState;

        if (isAuthenticated) {
          setDatadogRumUser({
            id: `${accessToken?.claims.uid}`
          });
        } else {
          clearDatadogRumUser();
        }

        setState({
          accessToken: accessToken as AccessToken,
          error,
          idToken: idToken as IDToken,
          isAuthenticated: isAuthenticated ?? false,
          refreshToken: refreshToken as RefreshToken
        });
      }
    });

    service.start();

    return () => {
      service.authStateManager.unsubscribe();
      service.stop();
    };
  }, []);

  const contextValue = useMemo(() => {
    return {
      configuration: oktaAuthConfiguration,
      signIn: async (path: string, tokens: Tokens) => {
        service.tokenManager.setTokens(tokens);

        await service.authStateManager.updateAuthState();

        replace({
          path: toRelativeUrl(path, redirectUri)
        });
      },
      signOut: async () => {
        await service.revokeRefreshToken();
        await service.revokeAccessToken(); // triggers authState update

        const { idToken } = service.tokenManager.getTokensSync();

        if (idToken) {
          await service.closeSession(); // triggers authState update
        } else {
          service.tokenManager.clear();
        }

        queryClient.clear();
      },
      state: state as AuthenticationState
    };
  }, [queryClient, replace, state]);

  if (!state) {
    return null;
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

import { Switch, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { MouseEventHandler, PropsWithChildren, useState } from 'react';

import { Anchor, NavAnchor, NavAnchorProps } from '~/components/anchor';
import { SignOutIcon } from '~/components/HeaderDefault';
import { LegalName, LegalNameStackType } from '~/components/legal-name';
import {
  PATH_ACCOUNT_ADDRESSES,
  PATH_ACCOUNT_ADDRESSES_WITHDRAWAL,
  PATH_ACCOUNT_DOCUMENTS,
  PATH_ACCOUNT_DOCUMENTS_STATEMENTS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_POSITIONS,
  PATH_ACCOUNT_TRANSACTIONS,
  PATH_ACCOUNTS,
  PATH_USER__PROFILE
} from '~/constants/paths';
import { useAccountContext } from '~/contexts/Account';
import { useAuthenticationContext } from '~/contexts/Authentication';
import { useUserContext } from '~/contexts/User';
import { useBodyScrollLock } from '~/hooks/use-body-scroll-lock';
import { LARGE_MEDIA_QUERY, useMediaQuery } from '~/hooks/use-media-query';
import NydigLockup from '~/images/nydig-logo-lockup.svg';
import NydigLogomark from '~/images/nydig-logo-logomark.svg';

interface BackdropProps {
  onClick: MouseEventHandler;
}

const Backdrop = ({ onClick }: BackdropProps): JSX.Element => {
  useBodyScrollLock();

  return (
    <Transition.Child
      enter="transition-opacity ease-in-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        aria-hidden
        className="bg-black fixed inset-0 opacity-50 transition-opacity z-50"
        onClick={onClick}
        tabIndex={-1}
      />
    </Transition.Child>
  );
};

interface MenuProps {
  onHide: MouseEventHandler;
  show: boolean;
}

const Menu = ({ children, onHide, show }: PropsWithChildren<MenuProps>): JSX.Element => {
  return (
    <Transition show={show}>
      <Backdrop onClick={onHide} />

      <div className="fixed max-h-80 w-full z-50">
        <Transition.Child
          enter="transition ease-in-out duration-75 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-150 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          {children}
        </Transition.Child>
      </div>
    </Transition>
  );
};

interface MenuToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const MenuToggle = ({ checked, onChange }: MenuToggleProps): JSX.Element => {
  const className =
    'absolute bg-white block duration-150 ease-in-out h-px hover:bg-opacity-75 rotate-0 transform transition-opacity transition-transform w-6';

  return (
    <Switch
      as="button"
      checked={checked}
      className="focus:outline-none h-8 hover:text-nydig-teal-100 inline-flex items-center justify-center select-none text-white w-8"
      onChange={onChange}
    >
      <span
        className={clsx(className, {
          '-translate-y-2': !checked,
          'rotate-45 translate-y-0': checked
        })}
      />
      <span
        className={clsx(className, { 'opacity-0': checked, 'opacity-100 translate-y-0': !checked })}
      />
      <span
        className={clsx(className, {
          '-rotate-45 translate-y-0': checked,
          'translate-y-2': !checked
        })}
      />
    </Switch>
  );
};

interface DividerProps {
  className?: string;
  variant: 'horizontal' | 'vertical';
}

const Divider = ({ className, variant }: DividerProps): JSX.Element => {
  return variant === 'horizontal' ? (
    <div className={clsx('bg-gray-400 h-px w-full', className)} />
  ) : (
    <div className={clsx('bg-gray-400 h-4 md:mx-6 mx-3 w-px', className)} />
  );
};

const NavItem = ({ className, ...restProps }: NavAnchorProps): JSX.Element => {
  return (
    <NavAnchor
      {...restProps}
      className={clsx(
        'border-solid hover:text-nydig-teal-100 inline-flex items-center select-none text-white',
        className
      )}
    />
  );
};

export const HeaderAccount = () => {
  const { signOut } = useAuthenticationContext();
  const isLarge = useMediaQuery(LARGE_MEDIA_QUERY, { noSsr: true });
  const {
    subaccount: { externalId, legalName }
  } = useAccountContext();
  const { name } = useUserContext();
  const [overflowMenuOpen, setOverflowMenuOpen] = useState<boolean>(false);
  const navItems: NavAnchorProps[] = [
    {
      children: 'Overview',
      match: { exact: true },
      to: {
        path: {
          pathname: PATH_ACCOUNT_OVERVIEW
        }
      }
    },
    {
      children: 'Positions',
      to: {
        path: {
          pathname: PATH_ACCOUNT_POSITIONS
        }
      }
    },
    {
      children: 'Transactions',
      to: {
        path: {
          pathname: PATH_ACCOUNT_TRANSACTIONS
        }
      }
    },
    {
      children: 'Addresses',
      match: {
        path: PATH_ACCOUNT_ADDRESSES
      },
      to: {
        path: {
          pathname: PATH_ACCOUNT_ADDRESSES_WITHDRAWAL
        }
      }
    },
    {
      children: 'Documents',
      match: {
        path: PATH_ACCOUNT_DOCUMENTS
      },
      to: {
        path: {
          pathname: PATH_ACCOUNT_DOCUMENTS_STATEMENTS
        }
      }
    }
  ];

  if (isLarge) {
    if (overflowMenuOpen) {
      setOverflowMenuOpen(false);
    }
  }

  return (
    <>
      <nav className="bg-black flex h-16 items-center md:px-6 px-4 shadow sticky top-0 w-full z-80">
        <Anchor className="inline-flex items-center justify-center" to={PATH_ACCOUNTS}>
          <NydigLogomark className="block w-8 xl:hidden" />
          <NydigLockup className="hidden xl:block" />
        </Anchor>
        <div className="flex-auto h-full inline-flex items-center justify-between">
          <Divider className="h-10" variant="vertical" />
          <div className="flex-auto h-full inline-flex items-center lg:hidden">
            <MenuToggle checked={overflowMenuOpen} onChange={setOverflowMenuOpen} />
          </div>
          <div className="flex-auto h-full inline-flex items-center justify-end lg:justify-between">
            <div className="h-full hidden items-center justify-center lg:inline-flex">
              <div className="flex-col h-full inline-flex items-center justify-center">
                <div className="flex-col inline-flex items-center justify-center text-white">
                  <div className="mt-1 text-xs flex">
                    <LegalName
                      className="max-w-10"
                      externalId={externalId}
                      legalName={legalName}
                      stack={LegalNameStackType.Always}
                    />
                  </div>
                </div>
              </div>
              <Divider className="h-6" variant="vertical" />
              <div className="gap-2 grid grid-flow-col auto-cols-auto h-full items-center justify-center">
                {navItems.map((item: NavAnchorProps, index: number) => (
                  <NavItem
                    {...item}
                    activeClassName="border-b-nydig-teal-100"
                    className="border-b-4 border-b-transparent border-t-4 border-t-transparent flex-col h-full hover:border-b-nydig-teal-100 justify-center px-2"
                    key={index}
                  />
                ))}
              </div>
            </div>
            <div className="h-full inline-flex items-center justify-end">
              <div className="gap-0 grid grid-flow-col auto-cols-auto h-full items-center justify-center lg:gap-2">
                <NavItem
                  activeClassName="border-b-nydig-teal-100"
                  className="border-b-4 border-b-transparent border-t-4 border-t-transparent flex-col h-full hover:border-b-nydig-teal-100 justify-center px-2"
                  to={{
                    path: {
                      pathname: PATH_USER__PROFILE
                    }
                  }}
                >
                  <div className="flex items-center">
                    <div className="bg-nydig-teal-100 flex h-6 items-center justify-center mr-0 rounded-full sm:mr-3 text-md text-white hover:text-white w-6">
                      {name.substring(0, 1).toUpperCase()}
                    </div>
                    <div className="hidden sm:block whitespace-nowrap">{name}</div>
                  </div>
                </NavItem>

                <button
                  className="border-b-4 border-b-transparent border-solid border-t-4 border-t-transparent focus:outline-none h-full hover:border-b-nydig-teal-100 hover:text-nydig-teal-100 inline-flex items-center justify-center px-2 select-none sm:w-auto text-white w-8"
                  onClick={signOut}
                  type="button"
                >
                  <span className="hidden lg:inline-flex lg:mr-3 whitespace-no-wrap">Sign Out</span>
                  <SignOutIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Menu onHide={() => setOverflowMenuOpen(!overflowMenuOpen)} show={overflowMenuOpen}>
        <div className="bg-white border-t border-gray-400 gap-5 grid max-h-screen overflow-auto px-4 py-4 shadow">
          <div className="flex">
            <div className="flex-auto flex items-center justify-between text-gray-900">
              <LegalName
                className="max-w-36 sm:max-w-sm"
                externalId={externalId}
                legalName={legalName}
              />
            </div>
          </div>
          <Divider variant="horizontal" />
          <div className="gap-5 grid">
            {navItems.map((item: NavAnchorProps, index: number) => (
              <NavItem
                {...item}
                activeClassName="border-l-nydig-teal-100"
                className="border-l-4 border-l-transparent hover:border-l-nydig-teal-100 pl-2 text-gray-900"
                key={index}
                onClick={() => setOverflowMenuOpen(false)}
              />
            ))}
          </div>
        </div>
      </Menu>
    </>
  );
};

import { ReactNode } from 'react';

export interface H1Props {
  children: ReactNode;
  id?: string;
}

export const H1 = ({ children, id }: H1Props) => {
  return (
    <h1 className="text-4xl font-medium pt-0 pb-3" id={id}>
      {children}
    </h1>
  );
};

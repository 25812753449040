import { useContext } from 'react';

import { Context, UserContext } from '~/contexts/User/context';

/**
 * Returns data about the current user.
 *
 * @returns UserContext
 * @throws Error
 */
export function useUserContext(): UserContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }

  return context;
}

import { Anchor, NavAnchor } from '~/components/anchor';
import { PATH_ACCOUNTS, PATH_AUTHENTICATION__SIGN_IN, PATH_USER__PROFILE } from '~/constants/paths';
import { useAuthenticationContext } from '~/contexts/Authentication';
import { useUserContext } from '~/contexts/User';
import NydigLockup from '~/images/nydig-logo-lockup.svg';
import NydigLogomark from '~/images/nydig-logo-logomark.svg';

const SignInIcon = (): JSX.Element => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <title>Sign In</title>
      <path
        clipRule="evenodd"
        d="M18.75 16.563c0 1.207-.98 2.187-2.188 2.187h-3.125a.937.937 0 110-1.875h3.125a.312.312 0 00.312-.313V3.438a.313.313 0 00-.312-.312h-3.125a.937.937 0 110-1.875h3.125a2.188 2.188 0 012.187 2.188v13.124z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.3 9.062H2.187a.937.937 0 100 1.875H9.3L6.837 13.4a.939.939 0 101.325 1.325l4.063-4.063a.937.937 0 000-1.325L8.162 5.275A.938.938 0 106.837 6.6L9.3 9.062z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const SignOutIcon = (): JSX.Element => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <title>Sign Out</title>
      <path
        d="M2.5 3.4375C2.5 2.23 3.48 1.25 4.6875 1.25H7.8125C8.06114 1.25 8.2996 1.34877 8.47541 1.52459C8.65123 1.7004 8.75 1.93886 8.75 2.1875C8.75 2.43614 8.65123 2.6746 8.47541 2.85041C8.2996 3.02623 8.06114 3.125 7.8125 3.125H4.6875C4.60462 3.125 4.52513 3.15792 4.46653 3.21653C4.40792 3.27513 4.375 3.35462 4.375 3.4375V16.5625C4.375 16.735 4.515 16.875 4.6875 16.875H7.8125C8.06114 16.875 8.2996 16.9738 8.47541 17.1496C8.65123 17.3254 8.75 17.5639 8.75 17.8125C8.75 18.0611 8.65123 18.2996 8.47541 18.4754C8.2996 18.6512 8.06114 18.75 7.8125 18.75H4.6875C4.10734 18.75 3.55094 18.5195 3.1407 18.1093C2.73047 17.6991 2.5 17.1427 2.5 16.5625V3.4375ZM15.55 9.0625H8.4375C8.18886 9.0625 7.9504 9.16127 7.77459 9.33709C7.59877 9.5129 7.5 9.75136 7.5 10C7.5 10.2486 7.59877 10.4871 7.77459 10.6629C7.9504 10.8387 8.18886 10.9375 8.4375 10.9375H15.55L13.0875 13.4C12.9954 13.4858 12.9215 13.5893 12.8703 13.7043C12.819 13.8193 12.7915 13.9435 12.7893 14.0693C12.787 14.1952 12.8102 14.3203 12.8573 14.437C12.9045 14.5537 12.9747 14.6598 13.0637 14.7488C13.1527 14.8378 13.2588 14.908 13.3755 14.9552C13.4922 15.0023 13.6173 15.0255 13.7432 15.0232C13.869 15.021 13.9932 14.9935 14.1082 14.9422C14.2232 14.891 14.3267 14.8171 14.4125 14.725L18.475 10.6625C18.6506 10.4867 18.7492 10.2484 18.7492 10C18.7492 9.75156 18.6506 9.51328 18.475 9.3375L14.4125 5.275C14.3267 5.18289 14.2232 5.10901 14.1082 5.05777C13.9932 5.00653 13.869 4.97898 13.7432 4.97676C13.6173 4.97454 13.4922 4.9977 13.3755 5.04485C13.2588 5.092 13.1527 5.16218 13.0637 5.2512C12.9747 5.34023 12.9045 5.44627 12.8573 5.563C12.8102 5.67974 12.787 5.80477 12.7893 5.93065C12.7915 6.05653 12.819 6.18067 12.8703 6.29567C12.9215 6.41067 12.9954 6.51417 13.0875 6.6L15.55 9.0625V9.0625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const HeaderDefault = () => {
  const {
    signOut,
    state: { isAuthenticated }
  } = useAuthenticationContext();
  const { name } = useUserContext();

  return (
    <nav className="bg-black flex h-16 items-center md:px-6 px-4 shadow sticky top-0 w-full z-80">
      <Anchor className="inline-flex items-center justify-center" to={PATH_ACCOUNTS}>
        <NydigLogomark className="block w-8 xl:hidden" />
        <NydigLockup className="hidden xl:block" />
      </Anchor>
      {isAuthenticated && (
        <div className="flex-auto h-full inline-flex items-center justify-between">
          <div className="flex-auto h-full inline-flex items-center justify-end">
            <div className="h-full inline-flex items-center justify-end">
              <div className="gap-0 grid grid-flow-col auto-cols-auto h-full items-center justify-center lg:gap-2">
                <NavAnchor
                  activeClassName="border-b-nydig-teal-100"
                  className="border-b-4 border-b-transparent border-solid border-t-4 border-t-transparent flex-col h-full hover:border-b-nydig-teal-100 hover:text-nydig-teal-100 inline-flex items-center justify-center px-2 select-none text-white"
                  to={{
                    path: {
                      pathname: PATH_USER__PROFILE
                    }
                  }}
                >
                  <div className="flex items-center">
                    <div className="bg-nydig-teal-100 flex h-6 items-center justify-center mr-0 rounded-full sm:mr-3 text-md text-white hover:text-white w-6">
                      {name.substring(0, 1).toUpperCase()}
                    </div>
                    <div className="hidden sm:block whitespace-nowrap">{name}</div>
                  </div>
                </NavAnchor>
                <button
                  className="border-b-4 border-b-transparent border-solid border-t-4 border-t-transparent focus:outline-none h-full hover:border-b-nydig-teal-100 hover:text-nydig-teal-100 inline-flex items-center justify-center px-2 select-none sm:w-auto text-white w-8"
                  onClick={signOut}
                  type="button"
                >
                  <span className="hidden lg:inline-flex lg:mr-3 whitespace-no-wrap">Sign Out</span>
                  <SignOutIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isAuthenticated && (
        <div className="flex-auto h-full inline-flex items-center justify-between">
          <div className="flex-auto h-full inline-flex items-center justify-end">
            <div className="h-full inline-flex items-center justify-end">
              <div className="gap-0 grid grid-flow-col auto-cols-auto h-full items-center justify-center lg:gap-2">
                <NavAnchor
                  activeClassName="border-b-nydig-teal-100"
                  className="border-b-4 border-b-transparent border-solid border-t-4 border-t-transparent flex-col h-full hover:border-b-nydig-teal-100 hover:text-nydig-teal-100 inline-flex items-center justify-center px-2 select-none text-white"
                  to={{
                    path: {
                      pathname: PATH_AUTHENTICATION__SIGN_IN
                    }
                  }}
                >
                  <div className="flex items-center">
                    <span className="hidden lg:inline-flex lg:mr-3 whitespace-no-wrap">
                      Sign In
                    </span>
                    <SignInIcon />
                  </div>
                </NavAnchor>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

import { useEffect } from 'react';
import { useToaster } from 'react-hot-toast';

import { toast } from '~/components/Toaster/actions';
import Toast from '~/components/Toaster/toast';
import { TOAST_DURATION_MIN_MS, TOAST_LIMIT } from '~/components/Toaster/types';
import { MEDIUM_MEDIA_QUERY, useMediaQuery } from '~/hooks/use-media-query';

export const Toaster = () => {
  const isMedium = useMediaQuery(MEDIUM_MEDIA_QUERY);
  const width = isMedium ? '595px' : '351px';
  const {
    handlers: { calculateOffset, endPause, startPause, updateHeight },
    toasts = []
  } = useToaster({
    duration: TOAST_DURATION_MIN_MS
  });

  // Limit number of toast
  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.remove(t.id));
  }, [toasts]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className="fixed top-16 z-90"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      role="alertdialog"
      style={{ right: '2rem', width }}
    >
      {toasts.map(({ height, id, message, ...restProps }) => {
        const offset = calculateOffset(id, {
          margin: 8,
          reverseOrder: false
        });
        const ref = (el: HTMLDivElement) => {
          if (el && !height) {
            updateHeight(id, el.getBoundingClientRect().height);
          }
        };
        return (
          <Toast {...restProps} id={id} key={id} offset={offset} ref={ref}>
            {message}
          </Toast>
        );
      })}
    </div>
  );
};

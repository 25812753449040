export const FOOTNOTE_OVERVIEW_PORTFOLIO_PERFORMANCE = 'FOOTNOTE_OVERVIEW_PORTFOLIO_PERFORMANCE';
export const FOOTNOTE_OVERVIEW_PORTFOLIO_LIST = 'FOOTNOTE_OVERVIEW_PORTFOLIO_LIST';
export const FOOTNOTE_OVERVIEW_RECENT_TRANSACTIONS = 'FOOTNOTE_OVERVIEW_RECENT_TRANSACTIONS';

export const FOOTNOTE_POSITIONS_DETAILS = 'FOOTNOTE_POSITIONS_DETAILS';
export const FOOTNOTE_POSITIONS_DETAILS_CHANGE = 'FOOTNOTE_POSITIONS_DETAILS_CHANGE';
export const FOOTNOTE_POSITIONS_DETAILS_QUANTITY = 'FOOTNOTE_POSITIONS_DETAILS_QUANTITY';
export const FOOTNOTE_POSITIONS_DETAILS_VALUE = 'FOOTNOTE_POSITIONS_DETAILS_VALUE';

export const FOOTNOTE_TRANSACTIONS_DETAILS = 'FOOTNOTE_TRANSACTIONS_DETAILS';
export const FOOTNOTE_TRANSACTIONS_DETAILS_ACTIVITY = 'FOOTNOTE_TRANSACTIONS_DETAILS_ACTIVITY';
export const FOOTNOTE_TRANSACTIONS_DETAILS_TYPE = 'FOOTNOTE_TRANSACTIONS_DETAILS_TYPE';
export const FOOTNOTE_TRANSACTIONS_DETAILS_VALUE = 'FOOTNOTE_TRANSACTIONS_DETAILS_VALUE';

export const FOOTNOTE_MARKET_PRICE = 'FOOTNOTE_MARKET_PRICE';
export const FOOTNOTE_WITHDRAWAL_ADDRESSES = 'FOOTNOTE_WITHDRAWAL_ADDRESSES';
export const FOOTNOTE_WITHDRAWAL_ADDRESSES_WITHDRAWAL_DATE =
  'FOOTNOTE_WITHDRAWAL_ADDRESSES_WITHDRAWAL_DATE';

export const FOOTNOTE_DEPOSIT_ADDRESSES = 'FOOTNOTE_DEPOSIT_ADDRESSES';

export const FOOTNOTES: Record<string, string> = {
  [FOOTNOTE_DEPOSIT_ADDRESSES]:
    '"Deposit Addresses" are the addresses to which transfers can be made to effect a deposit into your Custody Account. PLEASE DO NOT MAKE TRANSFERS WITHOUT OBTAINING AUTHORIZATION FROM NYDIG FOR EACH TRANSFER.',
  [FOOTNOTE_MARKET_PRICE]: 'Market price as of {%dates}.',
  [FOOTNOTE_OVERVIEW_PORTFOLIO_LIST]:
    'Market value of assets in your Custody Account as of {%dates}.',
  [FOOTNOTE_OVERVIEW_PORTFOLIO_PERFORMANCE]:
    'Total market value of selected digital assets in your Custody Account as of the end of each day. Does not reflect any cash in your Custody Account.',
  [FOOTNOTE_OVERVIEW_RECENT_TRANSACTIONS]:
    'Customer withdrawal, deposit, sale and purchase transactions that have settled in your Custody Account. Transactions for which you have received confirmations may not yet have settled, and so may not be reflected here. Additional details about the transactions may be included in your transaction confirmation.',
  [FOOTNOTE_POSITIONS_DETAILS]:
    'This page does not include the value of cash in your Custody Account.',
  [FOOTNOTE_POSITIONS_DETAILS_CHANGE]:
    'The change in market value of the relevant asset in your Custody Account on {%laterDate} compared to {%earlierDate}.',
  [FOOTNOTE_POSITIONS_DETAILS_QUANTITY]:
    'Only the assets labeled "Available to Trade" are in your custody account and are "Custodied Assets" within the meaning of your custody agreement with NYDIG Trust Company LLC and/or NYDIG Execution LLC (the "Custody Agreement"). Assets that have been transferred out of your custody account — for example, in connection with a repurchase transaction or pledged as collateral for a derivatives transaction — are not in your custody account, are not "Custodied Assets" within the meaning of the Custody Agreement, and are not "Available to Trade". "Collateral for Derivatives" refers to assets that you have transferred to NYDIG Derivatives Trading LLC as collateral for derivatives trades with that entity. "Collateral for USD Loan" refers to assets that you have transferred to NYDIG Funding LLC under a repurchase agreement. "Asset Loan" refers to assets that you have loaned to NYDIG Funding LLC under a lending agreement. Your rights with respect to Collateral for Derivatives, Collateral for USD Loan, or Asset Loan asset are determined solely by reference to the agreements governing those transactions. Such amounts shown are for informational purposes only, and if there is any conflict between this information and the relevant transaction agreements or any information sent to you by the relevant entities, the agreements and the information from the relevant entities shall control.',
  [FOOTNOTE_POSITIONS_DETAILS_VALUE]:
    'Market value of relevant asset in your Custody Account as of the same dates and times in footnote{%reference}.',
  [FOOTNOTE_TRANSACTIONS_DETAILS]:
    'This page does not include cash deposits to or withdrawals from Custody Account.',
  [FOOTNOTE_TRANSACTIONS_DETAILS_ACTIVITY]:
    'Withdrawal, deposit, sale and purchase transactions that have settled in your Custody Account. Transactions for which you have received confirmations may not yet have settled, and so may not be reflected here. Additional details about the transactions may be included in your transaction confirmation.',
  [FOOTNOTE_TRANSACTIONS_DETAILS_TYPE]:
    '"Collateral for Derivatives" refers to assets that you have transferred to NYDIG Derivatives Trading LLC as collateral for derivatives trades with that entity. "Collateral for USD Loan" refers to assets that you have transferred to NYDIG Funding LLC under a repurchase agreement. "Asset Loan" refers to assets that you have loaned to NYDIG Funding LLC under a lending agreement.',
  [FOOTNOTE_TRANSACTIONS_DETAILS_VALUE]:
    'For a withdrawal or deposit, the market value of the digital asset leg of the transaction as of the date settled. For a sale of digital assets for cash, reflects the net proceeds, meaning the total dollars that are remitted to you, after commissions. For a purchase of digital assets with cash, reflects the gross purchase price, meaning the purchase price of the digital assets plus commissions.',
  [FOOTNOTE_WITHDRAWAL_ADDRESSES]:
    '"Withdrawal Addresses" are external (non-NYDIG) addresses that you have provided to NYDIG for the purpose of making withdrawals and which NYDIG has approved.',
  [FOOTNOTE_WITHDRAWAL_ADDRESSES_WITHDRAWAL_DATE]:
    '"Whitelisted Date" represents the date upon which NYDIG approved the relevant Withdrawal Address.'
};

export type Action = {
  footnote: string;
};

export interface RegisterAction extends Action {
  data?: { [index: string]: unknown };
  type: ActionType.Register;
}

export interface UnregisterAction extends Action {
  type: ActionType.Unregister;
}

export type Actions = RegisterAction | UnregisterAction;

export enum ActionType {
  Register = 1,
  Unregister = -1
}

export type Footnote = {
  data?: { [index: string]: unknown };
  instances: number;
  symbol: number;
  value: string;
};
export type Footnotes = {
  [index: string]: Footnote;
};
/**
 * Adds a reference to a footnote.
 *
 * @param {String} footnote - The footnote to be registered.
 */
export type Register = (footnote: string, data?: { [index: string]: unknown }) => void;
/**
 * Removes a reference to a footnote.
 *
 * @param {String} footnote - The footnote to be unregistered.
 */
export type Unregister = (footnote: string) => void;

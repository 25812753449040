import { useContext } from 'react';

import { AuthenticationContext, Context } from './context';

/**
 * Returns data about the current auth session.
 *
 * @returns AuthenticationContext
 * @throws Error
 */
export function useAuthenticationContext(): AuthenticationContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useAuthenticationContext must be used within a AuthenticationContextProvider');
  }

  return context;
}

import clsx from 'clsx';

export enum LegalNameStackType {
  Always,
  AsNeeded,
  Never
}

interface LegalNameProps {
  className?: string;
  externalId: string | null;
  legalName: string | null;
  stack?: LegalNameStackType;
}

export const LegalName = ({
  className,
  externalId,
  legalName,
  stack = LegalNameStackType.Never
}: LegalNameProps) => {
  const shouldStack = stack !== LegalNameStackType.Never;

  return (
    <div
      className={clsx('flex justify-between', {
        'flex-col': stack === LegalNameStackType.Always,
        'flex-col sm:flex-row': stack === LegalNameStackType.AsNeeded,
        'items-center': !shouldStack,
        'items-start sm:items-center': shouldStack
      })}
    >
      <span className={clsx({ 'mr-0 sm:mr-1': shouldStack, 'mr-1': !shouldStack })}>
        {externalId}
      </span>
      <span className={clsx(className, 'inline-block overflow-hidden truncate')}>{legalName}</span>
    </div>
  );
};

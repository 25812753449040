import { Dispatch, ReactNode, useCallback, useMemo, useReducer } from 'react';
import { runty } from 'runty';

import {
  Actions,
  ActionType,
  Footnote,
  FOOTNOTES,
  Footnotes,
  RegisterAction
} from '~/contexts/Footnotes/types';

import { Context } from './context';

const initialState: Footnotes = {};

export interface FootnotesContextProviderProps {
  children: ReactNode;
}

const reducer = (state: Footnotes, { footnote, type, ...restAction }: Actions): Footnotes => {
  switch (type) {
    case ActionType.Register: {
      const previous = state[footnote];

      return {
        ...state,
        [footnote]: {
          data: { ...previous?.data, ...(restAction as RegisterAction).data },
          instances: (previous?.instances ?? 0) + 1,
          value: FOOTNOTES[footnote]
        }
      } as Footnotes;
    }

    case ActionType.Unregister: {
      if (state[footnote]?.instances > 1) {
        return {
          ...state,
          [footnote]: { ...state[footnote], instances: state[footnote].instances - 1 }
        } as Footnotes;
      }

      const { [footnote]: _, ...newState } = state;
      return newState as Footnotes;
    }

    default: {
      throw new Error(
        `FootnotesContextProvider: Unknown Action: ${type}. Action must be either ${ActionType.Register} or ${ActionType.Unregister}`
      );
    }
  }
};

export const FootnotesContextProvider = ({ children }: FootnotesContextProviderProps) => {
  const [state, dispatch]: [Footnotes, Dispatch<Actions>] = useReducer(reducer, initialState);
  const footnotes = useMemo(() => {
    const runt = runty({ asArray: true });

    return Object.entries(state).reduce(
      (
        previousValue: { [index: string]: Footnote },
        [footnote, { data, instances, value }]: [footnote: string, value: Footnote],
        index: number
      ) => {
        previousValue[footnote] = {
          data,
          instances,
          symbol: index + 1,
          value: runt(value)(data)
        };

        return previousValue;
      },
      {}
    );
  }, [state]);
  const register = useCallback(
    (footnote, data) => dispatch({ data, footnote, type: ActionType.Register }),
    []
  );
  const unregister = useCallback(
    (footnote) => dispatch({ footnote, type: ActionType.Unregister }),
    []
  );

  const contextValue = useMemo(
    () => ({
      footnotes,
      register,
      unregister
    }),
    [footnotes, register, unregister]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

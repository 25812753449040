import { Redirect as ReactRouterRedirect, RedirectProps } from 'react-router-dom';

import { Location, useGetLocationDescriptor } from '~/hooks/use-history';

export const Redirect = ({
  to,
  ...restProps
}: Omit<RedirectProps, 'to'> & { to: Location }): JSX.Element => {
  const getLocationDescriptor = useGetLocationDescriptor();
  const location = getLocationDescriptor(to);

  return <ReactRouterRedirect {...restProps} to={location} />;
};

import { Toast, ToastOptions } from 'react-hot-toast/dist/core/types';

export const TOAST_DURATION_MIN_MS = 4000;
export const TOAST_DURATION_MAX_MS = 10000;
export const TOAST_LIMIT = 1;

export type ToastErrorMessage = {
  error?: string | ((err: unknown) => string);
};
export type ToastSuccessMessage = {
  success: string | ((...args: unknown[]) => string);
};

export type ToastAllMessages = ToastErrorMessage & ToastSuccessMessage;
export type ToastMessages = ToastAllMessages | ToastErrorMessage | ToastSuccessMessage;

export enum ToastVariant {
  Error,
  Info,
  Success,
  Warning
}

export interface ToastArgs extends ToastOptions {
  message?: string;
  variant: ToastVariant;
}

export interface ToastReturn {
  (args: ToastArgs): string | undefined;
  dismiss: (toastId?: string | undefined) => void;
  remove: (toastId?: string | undefined) => void;
}

export interface ToastVariantConfig {
  defaultMessage?: string | undefined;
  duration: number;
  style: Toast['style'] | undefined;
}

import '~/data/schema/content';
import '~/index.css';

import { ErrorBoundary } from '@nydig/sweater-vest';
import { composeBeforeSend, initDatadog } from '@nydig/sweater-vest-datadog';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { ErrorFallback } from '~/components/error-fallback';
import { Toaster } from '~/components/Toaster';
import {
  __DATADOG_APPLICATION_ID__,
  __DATADOG_CLIENT_TOKEN__,
  __DATADOG_SERVICE__
} from '~/constants/env';
import { AuthenticationContextProvider } from '~/contexts/Authentication';
import { FootnotesContextProvider } from '~/contexts/Footnotes';
import { QueryClientProvider } from '~/contexts/QueryClient';
import { UserContextProvider } from '~/contexts/User';
import Pages from '~/pages';

initDatadog({
  applicationId: __DATADOG_APPLICATION_ID__,
  beforeSend: composeBeforeSend({
    redactedParams: []
  }),
  clientToken: __DATADOG_CLIENT_TOKEN__,
  service: __DATADOG_SERVICE__
});

render(
  <StrictMode>
    <Toaster />
    <BrowserRouter>
      <QueryClientProvider>
        <AuthenticationContextProvider>
          <ErrorBoundary fallback={ErrorFallback}>
            <UserContextProvider>
              <BrowserRouter>
                <FootnotesContextProvider>
                  <HelmetProvider>
                    <Pages />
                  </HelmetProvider>
                </FootnotesContextProvider>
              </BrowserRouter>
            </UserContextProvider>
          </ErrorBoundary>
        </AuthenticationContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

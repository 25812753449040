import { useLayoutEffect } from 'react';

export const useBodyScrollLock = () => {
  useLayoutEffect(() => {
    let previousBodyOverflowSetting: string;

    const disableBodyScroll = () => {
      // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
      // the responsiveness for some reason. Setting within a setTimeout fixes this.
      setTimeout(() => {
        previousBodyOverflowSetting = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
      }, 0);
    };

    const enableBodyScroll = () => {
      // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
      // the responsiveness for some reason. Setting within a setTimeout fixes this.
      setTimeout(() => {
        document.body.style.overflow = previousBodyOverflowSetting;
      }, 0);
    };

    disableBodyScroll();

    return () => enableBodyScroll();
  }, []); // Empty array ensures effect is only run on mount and unmount
};

/* eslint-disable react/button-has-type */

import clsx from 'clsx';
import { FocusEventHandler, MouseEvent, PropsWithChildren } from 'react';

export enum ButtonStyle {
  Default,
  Primary,
  White
}

export const buttonStyleHash: { [key: string]: string } = {
  [ButtonStyle.Default]:
    'bg-gray-50 border-2 border-nydig-royalblue-100 focus:border-nydig-royalblue-100 focus:bg-nydig-royalblue-200 focus:outline-none focus:text-white hover:border-nydig-royalblue-200 hover:text-nydig-royalblue-200 p-2 text-nydig-royalblue-100',
  [ButtonStyle.Primary]:
    'bg-nydig-royalblue-100 border-2 border-nydig-royalblue-100 focus:bg-nydig-royalblue-200 focus:outline-none hover:bg-nydig-royalblue-200 hover:border-nydig-royalblue-200 p-2 text-white',
  [ButtonStyle.White]:
    'bg-white border-2 border-white focus:border-gray-200 focus:outline-none hover:bg-gray-200 hover:border-gray-200 p-2 text-black'
};

export interface ButtonProps {
  buttonStyle?: ButtonStyle;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onBlur?: FocusEventHandler;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'reset' | 'submit';
}

const ButtonLoadingIndicator = () => (
  <div className="flex items-center justify-center p-1.5">
    <div className="animate-scale animation-delay-10 bg-white h-2.5 rounded-full w-2.5" />
    <div className="animate-scale animation-delay-20 bg-white h-2.5 mx-1.5 rounded-full w-2.5" />
    <div className="animate-scale animation-delay-30 bg-white h-2.5 rounded-full w-2.5" />
  </div>
);

export const Button = ({
  buttonStyle = ButtonStyle.Default,
  children,
  className,
  disabled,
  loading,
  onBlur,
  onClick,
  type = 'button'
}: PropsWithChildren<ButtonProps>) => {
  const style = buttonStyleHash[buttonStyle];

  return (
    <button
      className={clsx('h-11 min-w-28 mr-4 select-none w-max', style, className, {
        'opacity-50 pointer-events-none': disabled
      })}
      disabled={disabled}
      onBlur={onBlur}
      onClick={onClick}
      type={type}
    >
      {loading ? <ButtonLoadingIndicator /> : children}
    </button>
  );
};

import {
  PATH_ACCOUNT_ADDRESSES_DEPOSIT,
  PATH_ACCOUNT_ADDRESSES_WITHDRAWAL,
  PATH_ACCOUNT_DOCUMENTS_INVOICES,
  PATH_ACCOUNT_DOCUMENTS_STATEMENTS,
  PATH_ACCOUNT_DOCUMENTS_TRADE_CONFIRMATIONS,
  PATH_ACCOUNT_DOCUMENTS_TRANSACTION_CONFIRMATIONS,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_POSITIONS,
  PATH_ACCOUNT_TRANSACTIONS
} from './paths';

/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL = '/account/:externalId(\\d+)';
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_DEPOSIT = `${PATH_LEGACY_ACCOUNT_DETAIL}/addresses/deposit`;
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_WITHDRAWAL = `${PATH_LEGACY_ACCOUNT_DETAIL}/addresses/whitelisted`;
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_INVOICES = `${PATH_LEGACY_ACCOUNT_DETAIL}/documents/invoices`;
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_STATEMENTS = `${PATH_LEGACY_ACCOUNT_DETAIL}/documents/statements`;
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRADE_CONFIRMATIONS = `${PATH_LEGACY_ACCOUNT_DETAIL}/documents/:tradeConfirmationDocumentType(tradeconfirmation|trade-confirmations)`;
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRANSACTION_CONFIRMATIONS = `${PATH_LEGACY_ACCOUNT_DETAIL}/documents/:transactionConfirmationDocumentType(transactionconfirmation|transaction-confirmations)`;
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_POSITIONS = `${PATH_LEGACY_ACCOUNT_DETAIL}/positions`;
/**
 * Use the equivalent non-legacy PATH_ACCOUNT_* constant
 *
 * @deprecated
 */
export const PATH_LEGACY_ACCOUNT_DETAIL_TRANSACTIONS = `${PATH_LEGACY_ACCOUNT_DETAIL}/transactions`;

export const PATH_LEGACY_MAPPING: { [path: string]: string } = {
  [PATH_LEGACY_ACCOUNT_DETAIL]: PATH_ACCOUNT_OVERVIEW,
  [PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_DEPOSIT]: PATH_ACCOUNT_ADDRESSES_DEPOSIT,
  [PATH_LEGACY_ACCOUNT_DETAIL_ADDRESSES_WITHDRAWAL]: PATH_ACCOUNT_ADDRESSES_WITHDRAWAL,
  [PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_INVOICES]: PATH_ACCOUNT_DOCUMENTS_INVOICES,
  [PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_STATEMENTS]: PATH_ACCOUNT_DOCUMENTS_STATEMENTS,
  [PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRADE_CONFIRMATIONS]:
    PATH_ACCOUNT_DOCUMENTS_TRADE_CONFIRMATIONS,
  [PATH_LEGACY_ACCOUNT_DETAIL_DOCUMENTS_TRANSACTION_CONFIRMATIONS]:
    PATH_ACCOUNT_DOCUMENTS_TRANSACTION_CONFIRMATIONS,
  [PATH_LEGACY_ACCOUNT_DETAIL_POSITIONS]: PATH_ACCOUNT_POSITIONS,
  [PATH_LEGACY_ACCOUNT_DETAIL_TRANSACTIONS]: PATH_ACCOUNT_TRANSACTIONS
};

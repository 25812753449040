import { useQuery, UseQueryResult } from 'react-query';

import { CurrentUser } from '~/data/types/current-user';
import { useGetRequest } from '~/hooks/use-fetch';

export function useCurrentUserQuery(enabled = true): UseQueryResult<CurrentUser, Error> {
  const get = useGetRequest<CurrentUser>();
  const url = '/user/current';

  return useQuery<CurrentUser, Error>([url], async (): Promise<CurrentUser> => get(url), {
    enabled
  });
}

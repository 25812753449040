/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';
import locale from 'yup/lib/locale';

interface MessageParams {
  label: string;
  originalValue: Date | boolean | number | string;
  path: string;
  type: string;
  value: Date | boolean | number | string;
}

type Message<Extra extends Record<string, unknown> = {}> =
  | Record<PropertyKey, unknown>
  | string
  | ((params: Extra & MessageParams) => unknown);

interface MixedLocale {
  default?: Message;
  defined?: Message;
  notOneOf?: Message<{ values: boolean[] | Date[] | number[] | string[] }>;
  notType?: Message;
  oneOf?: Message<{ values: boolean[] | Date[] | number[] | string[] }>;
  required?: Message;
}

interface StringLocale {
  email?: Message<{ regex: RegExp }>;
  length?: Message<{ length: number }>;
  lowercase?: Message;
  matches?: Message<{ regex: RegExp }>;
  max?: Message<{ max: number }>;
  min?: Message<{ min: number }>;
  trim?: Message;
  uppercase?: Message;
  url?: Message<{ regex: RegExp }>;
  uuid?: Message<{ regex: RegExp }>;
}

interface NumberLocale {
  integer?: Message;
  lessThan?: Message<{ less: number }>;
  max?: Message<{ max: number }>;
  min?: Message<{ min: number }>;
  moreThan?: Message<{ more: number }>;
  negative?: Message<{ less: number }>;
  positive?: Message<{ more: number }>;
}

interface DateLocale {
  max?: Message<{ max: Date | string }>;
  min?: Message<{ min: Date | string }>;
}

interface ObjectLocale {
  noUnknown?: Message;
}

interface ArrayLocale {
  length?: Message<{ length: number }>;
  max?: Message<{ max: number }>;
  min?: Message<{ min: number }>;
}

interface BooleanLocale {
  isValue?: Message;
}

const mixed: Required<MixedLocale> = {
  default: 'Please enter a valid value',
  defined: 'Please enter a value',
  notOneOf: 'This field cannot be one of these values: ${values}',
  notType: (params) => {
    if (params.type === 'date') {
      return 'Please enter a valid value';
    }
    return locale.mixed.notType(params);
  },
  oneOf: 'Please enter one of the following values: ${values}',
  required: 'Please enter a value'
};

const string: Required<StringLocale> = {
  email: 'Please enter a valid email',
  length: 'Please enter a value of ${length} characters',
  lowercase: 'Please enter a lowercase string',
  matches: 'Please enter a valid value',
  max: 'Please enter at most ${max} characters',
  min: 'Please enter at least ${min} characters',
  trim: 'Please enter a trimmed string',
  uppercase: 'Please enter a upper case string',
  url: 'Please enter a valid URL',
  uuid: 'Please enter a valid UUID'
};

const number: Required<NumberLocale> = {
  integer: 'Please enter an integer',
  lessThan: 'Please enter a value less than ${less}',
  max: 'Please enter a value less than or equal to ${max}',
  min: 'Please enter a value greater than or equal to ${min}',
  moreThan: 'Please enter a value greater than ${more}',
  negative: 'Please enter a negative number',
  positive: 'Please enter a positive number'
};

const date: Required<DateLocale> = {
  max: 'Please select a value earlier than ${max}',
  min: 'Please select a value later than ${min}'
};

const boolean: BooleanLocale = {
  isValue: '${path} field must be ${value}'
};

const object: Required<ObjectLocale> = {
  noUnknown: '${path} field has unspecified keys: ${unknown}'
};

const array: Required<ArrayLocale> = {
  length: 'Please select ${length} items',
  max: 'Please select at most ${max} item(s)',
  min: 'Please select at least ${min} item(s)'
};

const errorMessages = Object.assign(Object.create(null), {
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  string
});

setLocale(errorMessages);

declare global {
  interface Window {
    APP_OKTA_CLIENT_ID: string;
    APP_OKTA_ISSUER: string;
  }
}

// https://app.datadoghq.com/rum/application/a8a87726-3f39-4387-850e-3dd5edf042aa/edit
export const __DATADOG_APPLICATION_ID__ = 'a8a87726-3f39-4387-850e-3dd5edf042aa';
export const __DATADOG_CLIENT_TOKEN__ = 'pub11b46c0e349704269e159292e91dbaf3';
export const __DATADOG_SERVICE__ = 'portal';

export const __APP_OKTA_CLIENT_ID__ =
  __NODE_ENV_DEVELOPMENT__ || __NODE_ENV_TEST__
    ? process.env.APP_OKTA_CLIENT_ID!
    : window.APP_OKTA_CLIENT_ID;
// Runtime env var
export const __APP_OKTA_ISSUER__ =
  __NODE_ENV_DEVELOPMENT__ || __NODE_ENV_TEST__
    ? process.env.APP_OKTA_ISSUER!
    : window.APP_OKTA_ISSUER;
